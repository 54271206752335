
import * as React from 'react';
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocalization } from '@progress/kendo-react-intl';
import headerBg from '../assets/header-bg.png';
import { SITE_BASE_PATH } from '../constant';
import { selectHeaderData } from '../redux/headerSlice';
import "./Header.scss"


export const Header = (props) => {
    const { onButtonClick } = props;
    const history = useHistory();
    const location = useLocation();
    const localizationService = useLocalization();
    const header_data = useSelector(selectHeaderData);
    const [show, setShow] = useState(false);

    const NOTAUTH_PATH = [
        `${SITE_BASE_PATH}profile-details`,
    ];

    const user_full_name = localStorage.getItem('sd-user-full_name');

    const logOut = () => {
        localStorage.setItem('kdksoft-token', null);
        localStorage.setItem('sd-user-role', null);
        localStorage.setItem('sd-user-full_name', null);
        history.push(`${SITE_BASE_PATH}login`);
    };



    return (
        <header className="header" style={{ backgroundImage: `url(${headerBg})` }}>
            <div className="nav-container">
                <div className="menu-button" style={{ cursor: 'pointer' }}>
                    <span className={'k-icon k-i-menu'} onClick={onButtonClick} />
                </div>

                <div className="title">
                    <h1>{localizationService.toLanguageString('custom.subscriptionDetail')}</h1>
                </div>

                {location.pathname.indexOf('signup') === -1
                    &&
                    <div className="userprodropd">
                        <ul className="">
                            <li className="nav-item dropdown userprodropdlist cursor_pointer" style={{ top: '12px', right: "3rem" }}>
                                <span className="nav-link dropdown-toggle"  id="navbardrop" data-toggle="dropdown" aria-expanded="false" onClick={() => setShow(!show)}>
                                    <span className="profile_img_bg dark_color_text">
                                        <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg>
                                    </span>
                                </span>
                                <div className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`}>
                                   {!NOTAUTH_PATH.includes(location.pathname) && <>
                                        <span className="black_color_text usertextoverflow"
                                            style={{
                                                fontSize: '12px',
                                                padding: '0rem',
                                                marginTop: '0rem',
                                                borderRadius: '0rem'
                                            }}>
                                            <span className="pl-0" data-toggle="tooltip" data-placement="top"
                                                title={header_data?.full_name ?? user_full_name}
                                            >
                                                {header_data?.full_name ?? user_full_name}
                                            </span>
                                        </span>
                                    <span className="activeusrp">
                                        <FontAwesomeIcon icon={faCircle} />
                                    </span>
                                    <div className="dropdown-divider" />
                                    <Button
                                        className="dropdown-item"
                                        onClick={() => {
                                            history.push(`${SITE_BASE_PATH}profile`);
                                            setShow(!show)
                                        }}
                                        style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                    >
                                        Profile Settings
                                    </Button>
                                    
                                    <div className="dropdown-divider" />
                                    </>}
                                    <Button className="dropdown-item logdisinline" id="zps_logout_button" onClick={(e) => logOut(e)} style={{ fontSize: '12px' }}>
                                        Logout
                                        <span class=" logicntopm">
                                            <span className={'k-icon k-i-logout'} style={{ marginTop: '3px' }} />
                                        </span>

                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </div>}

            </div>
        </header >
    );
}

Header.displayName = 'Header';
Header.propTypes = {
    page: PropTypes.string,
    onButtonClick: PropTypes.func
};